/**
 * 关于平台评价方面的接口
 * 2022-02-06
 * author DuZeFang
 */
import request from '@/utils/request'

export default class AppraiseApi {
    // 列表 - 不需要登陆
    static index(params) {
        return request({
            url: '/appraise/index',
            method: 'post',
			data: params
        })
    }

    // 列表
    static list(params) {
        return request({
            url: '/appraise/list',
            method: 'post',
            data: params
        })
    }
}