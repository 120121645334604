<template>
	<div id="tu-home">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="14">
				<div class="content">
					<div class="item">
						<div id="Bio" class="short-title">My Bio</div>
						<div><strong>{{ info.tagline || '' }}</strong></div>
						<div v-if="info.bio">
							<div style="margin-top: 10px;" v-html="info.bio"></div>
						</div>
						<div v-else>
							<el-empty :image-size="200"></el-empty>
						</div>
					</div>
					<div class="item">
						<div id="Reviews" class="short-title">My Reviews</div>
						<div v-if="reviews.length > 0">
							<div class="review" v-for="item,index in reviews" :key="index">
								<div class="user_info">
									<el-avatar :size="80" v-if="item.user1.avatar_url" :src="item.user1.avatar_url"></el-avatar>
									<el-avatar :size="80" v-else style="font-size: 55px;">{{ item.user1.slot }}</el-avatar>
									<div>{{ item.user1.nickname }}</div>
								</div>
								<div class="info">
									<el-rate v-model="item.star" disabled size="large" :colors="rate_colors"></el-rate>
									<div class="content">
										{{ item.content }}
									</div>
									<div class="date">
										{{ item.date_time }}
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<el-empty :image-size="200"></el-empty>
						</div>
					</div>
					<div class="item">
						<div id="Availability" class="short-title">My Availability</div>
						<div v-if="availability" class="operation-header">
							<div class="time-interval">
								<el-icon @click="getTutorAvailability(-1)"><i class="fa fa-chevron-left"></i></el-icon>
								<el-icon @click="getTutorAvailability(1)"><i class="fa fa-chevron-right"></i></el-icon>
								<div class="show-date">
									{{ availabilityData.showDate }}
								</div>
							</div>
							<div>
								<el-button-group class="ml-4">
									<el-button type="primary" size="small" :plain="timezone==1?false:true" @click="selectTimeZone(1)">中国</el-button>
									<el-button type="primary" size="small" :plain="timezone==2?false:true" @click="selectTimeZone(2)">英国</el-button>
								</el-button-group>
							</div>
						</div>
						<div v-if="availability" class="tu-time-content">
							<div class="time-datetime">
								<div class="header" v-for="item, index in availability" :key="index">
									<span class="day">{{ item.day }}</span>
									<span class="week">{{ item.ddd }}</span>
								</div>
							</div>
							<div class="time-datetime" v-for="item, index in availabilityMax" :key="index">
								<div class="time-wrap" v-for="it, i in availability">
									<div>
										<span v-if="!!!it.children[index]" class="nothing">No</span>
										<span v-else-if="it.children[index].ok==1" class="booked">Booked</span>
										<span v-else-if="it.children[index].ok==2" class="rested">Leave</span>
										<span v-else-if="it.children[index].ok==3" class="nobook">{{ it.children[index].hi }}</span>
										<span v-else>{{ it.children[index].hi }}</span>
										<!-- <span>--:--</span> -->
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<el-empty :image-size="200"></el-empty>
						</div>
					</div>
					<div class="item" v-if="!!info && !!info.subject">
						<div id="Subjects" class="short-title">My Subjects</div>
						<div>I teach the following subjects:</div>
						<div>
							<block v-if="subjects" v-for="item, index in subjects" :key="index">
								<div style="margin-top: 10px;"><strong>{{ item.name }}</strong></div>
								<div class="tu-tag">
									<el-tag type="info" v-for="it, key in item.children" :key="key">{{ it.name }}</el-tag>
								</div>
							</block>
							<block v-else>
								<el-empty :image-size="200"></el-empty>
							</block>
						</div>
					</div>
					<div class="item">
						<div id="Qualifications" class="short-title">My Qualifications</div>
						<div>
							<div style="margin-top: 10px;"><strong>University</strong></div>
							<div class="tu-quali">
								<el-table :data="universityData">
									<el-table-column prop="name" label="Name" />
									<el-table-column prop="subject" label="Subject" />
									<el-table-column prop="grade" label="Grade" />
								</el-table>
							</div>
							<div style="margin-top: 10px;"><strong>College</strong></div>
							<div class="tu-quali">
								<el-table :data="collegeData">
									<el-table-column prop="name" label="Name" />
									<el-table-column prop="subject" label="Subject" />
									<el-table-column prop="grade" label="Grade" />
								</el-table>
							</div>
							<div style="margin-top: 10px;"><strong>Other</strong></div>
							<div class="tu-quali">
								<el-table :data="otherData">
									<el-table-column prop="name" label="Name" />
									<el-table-column prop="subject" label="Subject" />
									<el-table-column prop="grade" label="Grade" />
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
		    <div class="avatar" v-if="info">
	    		<div class="block">
	          <el-avatar :size="180" v-if="info.avatar_url" :src="info.avatar_url"></el-avatar>
	          <el-avatar :size="180" v-else>{{ info.slot }}</el-avatar>
	        </div>
					<div class="fee">
						<!-- jpy gbp -->
						<span>{{ info.rate || 0 }}<block class="currency">{{ info.currency }}</block></span>/hr
					</div>
					<div class="nickname">
						<span>{{ info.nickname || '' }}</span>
						<el-image v-if="info.background_check==1" style="width: 32px;margin-left: 8px;" :src="require('@/assets/check.png')" fit="fill"></el-image>
					</div>
					<div class="total">
						<div>
							<div><el-icon><i class="fa fa-clock-o" aria-hidden="true"></i></el-icon></div>
							<div>{{ info.hours }}</div>
						</div>
						<div>
							<div><el-icon><i class="fa fa-send" aria-hidden="true"></i></el-icon></div>
							<div>{{ info.reply }}</div>
						</div>
						<div v-if="info.video">
							<div><el-icon><i class="fa fa-video-camera" aria-hidden="true"></i></el-icon></div>
							<div>{{ $t('self-introduction') }}</div>
						</div>
					</div>
		    </div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import TeacherApi from '@/api/teacher'
import AppraiseApi from '@/api/appraise'
import TimeControl from '@/utils/time-control'

export default {
	name: "tu-home",
	components: {
		SecondMenu
	},
	data() {
		return {
			info: {},
			reviews: [],
			availabilityData: [],
			availabilityMax: 0,
			universityData: [],
			collegeData: [],
			otherData: [],
			timezone: 0,
			rate_colors: ['#99A9BF', '#F7BA2A', '#FF9900']
		}
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType', 'subjectData'
		]),
		subjects() {
			let subjects = [];
			if(!!this.info.subject) {
				for(let i in this.subjectData.subject) {
					let subject = [];
					for(let k in this.subjectData.subject[i].children) {
						if(this.info.subject.indexOf(parseInt(k)) != -1 || this.info.subject.indexOf(k) != -1) {
							subject.push(this.subjectData.subject[i].children[k]);
						}
					}
					if(subject.length > 0) {
						subjects.push({
							id: this.subjectData.subject[i].id,
							name: this.subjectData.subject[i].name,
							children: subject
						})
					}
				}
			}
			return subjects || false;
		},
		availability() {
			if(Object.keys(this.availabilityData).length == 0) {
				return [];
			}
			let timezone = this.timezone == 1 ? 'Asia/Shanghai' : (this.timezone == 2 ? 'Europe/London' : '');
			this.availabilityData.startDate = TimeControl.getFormatTime(this.availabilityData.start, "DD/MM/YYYY", timezone);
			this.availabilityData.endDate = TimeControl.getFormatTime(this.availabilityData.end, "DD/MM/YYYY", timezone);
			this.availabilityData.showDate = TimeControl.getFormatTime(this.availabilityData.start, "DD MMM", timezone) + ' - ' + TimeControl.getFormatTime(this.availabilityData.end, "DD MMM, YYYY", timezone);
			let availability = {};
			let di = 0;
			while(di < 8) {
				let ymd = TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "DD/MM/YYYY", timezone);
				availability[ymd] = {
					day: TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "DD", timezone),
					ddd: TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "ddd", timezone),
					children: []
				}
				if(ymd == this.availabilityData.endDate) {
					break;
				}
				di += 1;
			}
			// console.log(this.availabilityData)
			if(!!this.availabilityData.times) {
				for(let i in this.availabilityData.times) {
					let ymd = TimeControl.getFormatTime(this.availabilityData.times[i].time, "DD/MM/YYYY", timezone);
					if(!!availability[ymd]) {
						availability[ymd].children.push({
							hi: TimeControl.getFormatTime(this.availabilityData.times[i].time, "HH:mm", timezone),
							ok: this.availabilityData.times[i].is_ok
						});
					}					
				}
			}
			for(let k in availability) {
				if(availability[k].children.length > this.availabilityMax) {
					this.availabilityMax = availability[k].children.length;
				}
			}
			return availability;
		}
	},
	created() {
		this.$store.dispatch('GetSubjectData')
		this.timezone = TimeControl.getTimeZone()
		if(!!this.userInfo) {
			this.timezone = this.userInfo.timezone;
		}
		this.timezone = TimeControl.getZoneCode(this.timezone);
		this.getApiInfoData()
		this.getApiTutorData()
	},
	methods: {
		getApiInfoData() {
			TeacherApi.info().then(res => {
				if(!!res.data) {
					this.info = res.data
					let bio_arr = []
					if(!!res.data.bio) {
						res.data.bio.split('\n').forEach(item=>bio_arr.push(`<p>${item.trim()}</p>`));
					}
					this.info.bio = bio_arr.join('');
				}
			});
		},
		getApiTutorData() {
			AppraiseApi.index({prePage: 5}).then(res => {
				this.reviews = res.data;
			});
			this.getTutorAvailability();
			TeacherApi.qualifications().then(res => {
				if(!!res.data[1]) {
					this.universityData = res.data[1];
				}
				if(!!res.data[2]) {
					this.collegeData = res.data[2];
				}
				if(!!res.data[3]) {
					this.otherData = res.data[3];
				}
			})
		},
		selectTimeZone(value) {
			this.timezone = value;
		},
		getTutorAvailability(index) {
			let tz = this.availabilityData ? this.availabilityData.tz : 0;
			if(!!index) {
				tz = tz + index*1;
				if(tz < 0) {
					return false;
				}
			}
			let params = {
				tz: tz
			}
			TeacherApi.zoneAvailability(params).then(res => {
				this.availabilityData = res.data;
			})
		}
	}
}
</script>
<style lang="less">
#tu-home {
	.el-table th.el-table__cell {
		background-color: unset;
	}
	.content {
		font-family: Roboto,sans-serif;
	    font-size: .875rem;
	    font-weight: 400;
	    line-height: 1.6;
	    color: #595959;
	    text-align: left;

		.short-title {
	    	font-size: 1.5rem;
	    	line-height: 1.8;
	    	color: var(--el-color-primary);
	    }

		.item {
			box-shadow: var(--el-box-shadow-light);
			padding: 18px;
			margin-bottom: 15px;

			.tu-tag {

				.el-tag {
					margin: 8px;
				}
			}

			.tu-quali {
				margin: 8px 0;
			}

			.operation-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.time-interval {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				.el-icon {
					border: 2px solid var(--el-color-primary);
					padding: 5px;
					color: var(--el-color-primary);
					margin: 0 2px;
					cursor: pointer;
				}

				i.el-icon:hover {
					background: var(--el-color-primary);
					color: var(--el-color-white);
				}

				.show-date {
					font-size: 18px;
					font-weight: 500;
					padding: 0 15px;
				}
			}

			.tu-time-content {
				// background: var(--el-color-info-lighter);
				border: 1px solid var(--el-border-color-lighter);
				margin-top: 15px;

				.time-datetime:first-child {
					line-height: 42px;
					background: var(--el-color-white);
				}

	  		.time-datetime {
					display: flex;
			    flex-direction: row;
			    align-items: center;
			    // margin-top: 12px;
			    width: 100%;

			    .header, .time-wrap {
			    	width: calc(100% / 7);
			    	text-align: center;
			    }

			    .header {
				    font-weight: 600;

						.day {
							font-size: 19px;
							color: #424B5B;
							letter-spacing: -0.28px;
						}

						.week {
							font-size: 12px;
							color: #424B5B;
							letter-spacing: -0.18px;
							margin-left: 5px;
						}
					}

					.time-wrap {
						-moz-user-select: none; /*火狐*/
						-webkit-user-select: none; /*webkit浏览器*/
						-ms-user-select: none; /*IE10*/
						-khtml-user-select: none; /*早期浏览器*/
						-o-user-select: none; /* Opera*/
						user-select: none;
						span {
							display: block;
						  background: gray;
							margin: 4px 12px;
							color: #FFF;
							padding: 4px 0;
							cursor: pointer;
						}

						span.nothing {
							background: var(--el-color-info-lighter);
						}

						span.booked {
							background: var(--el-color-primary);
						}

						span.rested {
							background: var(--el-color-error);
						}

						span.nobook {
							background: var(--el-color-info-light);
						}


						// span:first-child {
						// 	color: var(--el-color-primary);
						// }

						// span:last-child {
						// 	color: #F40002;
						// }
					}
	  		}
			}
		}
	}

	.avatar {
		background: var(--el-color-info-lighter);
		text-align: center;
		padding: 45px 0 65px 0;
		border-radius: 15px;

		.fee {
			color: var(--el-color-danger);
			font-weight: 600;
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 0;
			margin: 138px 42px 0 0;
			overflow: hidden;

			span {
				background: var(--el-color-danger);
				color: var(--el-color-white);
				font-size: 28px;
				border-radius: 50%;
				width: 85px;
				height: 85px;
				line-height: 85px;
				display: inline-block;

				.currency {
					font-size: 18px;
				}
			}			
		}
		.nickname {
			margin: 12px 0;
			font-size: 22px;
			color: var(--el-color-primary);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			span {
				font-weight: 600;
			}
		}
		.total {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 25px;

			div {
				line-height: 1.6;
				width: 100%;
				font-size: 14px;

				.el-icon {
					font-size: 16px;
				}
			}
		}
	}

	.review {
		display: flex;
		margin: 12px;

		.user_info {
			text-align: center;
			width: 15%;
		}

		.info {
			margin-left: 12px;
			width: 100%;
			.content {
				min-height: 60px;
			}

			.date {
				text-align: right;
				color: var(--el-color-info);
			}
		}
	}
}
</style>